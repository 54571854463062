$btn-border-radius: 2.5rem;
$btn-border-radius-lg: 2.5rem;
$font-family-sans-serif: "Work Sans", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$primary: #172A39;
$info: #566880;
$secondary: #566880;
$input-border-radius: 7px;
$input-padding-x: 12px;
$input-padding-y: 12px;
$input-line-height: 1.1;
$success: #FFF4E5;
$success-text: #521262;

/*$form-check-input-width: 2em;*/



@import "bootstrap/scss/bootstrap";

h1,
h2,
h3,
h4,
h5 {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

input::placeholder {
    color: #521262 !important;
}

label {
    font-weight: 300;
}

.content-container {
    min-height: 630px;
}

.form-check-label,
.form-check {
    font-weight: 300 !important;
    color: rgb(48, 60, 66);
}

.menu {
    background-color: #172A39;
    color: white;
}

.menu-item {
    background-color: #D1FF7E !important;
    color: #172A39;
}

.bg-dark {
    /*background-color: #002e6d !important;*/
    background-color: white !important;

}

.btn-primary {
    color: #fff;
}


// body {
//     background-color: #ececec;
// }


a,
a:focus,
button,
button:focus {
    outline: none;
}


.clicker {
    cursor: pointer;
}


.forbidden {
    opacity: 0.5;
    background-color: dimgrey;
    color: linen;
    cursor: not-allowed;
}

.full-square {
    border: 0;
    width: 100%;
    border-radius: 0rem !important;
}

.accordion-button {
    width: 100%;
    font-size: 1rem !important;
    align-items: center;
    border: 0;
    border-radius: 0;
    display: flex;
    overflow-anchor: none;
    position: relative;
    text-align: left;
}

.desktop-menu {
    padding-top: 0.36rem !important;
    padding-bottom: 1.1rem !important;
    font-size: 1rem !important;
}

.phone-button {
    margin-top: 1rem !important;
    margin-right: 3rem !important;
}

.broker-logo {
    /*
    position: fixed;
    top: 10px;
    left: 80px;*/
    /*width: 300px;*/
    height: 36px;
    margin-top: 4px;
    /*
    margin-left: 19px;
    margin-top: 2px;
    padding-left: 5px;
    */
    /*height: 4.12rem;*/
}

.premium-card {
    background-color: #172A39 !important;
    color: #D1FF7E !important;
}

.broker-logo-mobile {
    height: 2rem;
    margin-top: 1rem;
}

.plan-picker {
    /*
    background-color: #566880 !important;
    color: white !important;
    */
    background-color: #D1FF7E;
    border-radius: 7px !important;
    color: #172A39;
}

.footer-area {
    background-color: #172A39 !important;
    color: white;
    margin-bottom: -20px;
}

.footer-secure {
    /*color: rgb(40, 167, 69);*/
    color: #D1FF7E !important;
}

.footer-area a a:link {
    color: white;
    text-decoration: none;
}

.footer-area a:hover {
    color: rgb(237, 246, 243);
    text-decoration: none;
}

.footer-link {
    color: rgb(237, 246, 243);
    text-decoration: none;
}

.footer-link:hover {
    color: white;
    text-decoration: none;
}

.custom-progress {
    border-radius: 3rem !important;
}

#dropdown-basic {
    background-color: #566880 !important;
    color: #D1FF7E;
}